import $ from "jquery"; // events - module loading happens exactly once

export var events = $({});
export var trigger = function trigger() {
  events.trigger.apply(events, arguments);
};
export var on = function on() {
  events.on.apply(events, arguments);
};
export var one = function one() {
  events.one.apply(events, arguments);
};
export var off = function off() {
  events.off.apply(events, arguments);
};