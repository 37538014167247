import $ from "jquery";
export class ActivateWidget {
  constructor() {
    this.title = "Activate";
  }

  mount() {
    var el = $(".thebe-activate");
    el.replaceWith("<div class=\"thebe-activate\">\n      <button type=\"button\" class=\"thebe-activate-button\" onclick=\"thebelab.bootstrap()\">".concat(this.title, "</button>\n    </div>"));
  }

}